const submitBackendTracking = (tracking, user) => {
  if (tracking) {
    if (typeof window !== 'undefined') {
      if (window._paq) {
        try {
          switch (tracking.event) {
            case 'register':
              if (user && user.email) {
                _paq.push(['setUserId', user.email]);
              }
              window._paq.push(['trackEvent', 'Accounts', 'Registration']);
              if (window.gtag) {
                window.gtag('event', 'conversion', {
                  'send_to': 'AW-687753180/MAqLCL3D7MAZENyP-ccC'
                });
              }
              if (window.fbq) {
                window.fbq('track', 'CompleteRegistration');
              }
              window.uetq = window.uetq || [];
              window.uetq.push('event', 'signup', {});
              if (user && user.email) {
                window.uetq.push('set', {'pid': {
                  'em': user.email
                }});
              }
              break;
            case 'login':
              if (user && user.email) {
                _paq.push(['setUserId', user.email]);
              }
              window._paq.push(['trackEvent', 'Accounts', 'Sessions', 'Login']);
              break;
            case 'logout':
              window._paq.push(['trackEvent', 'Accounts', 'Sessions', 'Logout']);
              _paq.push(['resetUserId']);
              break;
            // case 'forgot_password':
            //   window._paq.push(['trackEvent', 'Accounts', 'Sessions', 'Forgot Password']);
            //   break;
            // case 'reset_password':
            //   window._paq.push(['trackEvent', 'Accounts', 'Sessions', 'Reset Password']);
            //   break;
            // case 'update_password':
            //   window._paq.push(['trackEvent', 'Accounts', 'Sessions', 'Update Password']);
            //   break;
            // case 'update_profile':
            //   window._paq.push(['trackEvent', 'Accounts', 'Sessions', 'Update Profile']);
            //   break;
            case 'delete_account':
              window._paq.push(['trackEvent', 'Accounts', 'Sessions', 'Delete Account']);
              _paq.push(['resetUserId']);
              break;
            case 'schedule':
              window._paq.push(['trackEvent', 'Posts', 'Schedule']);
              break;
            case 'message':
              window._paq.push(['trackEvent', 'Posts', 'Message']);
              break;
            default:
              break;
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
  }
};

if (typeof window !== 'undefined') {
  window.submitBackendTracking = submitBackendTracking;
}
export default submitBackendTracking;